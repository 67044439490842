.question-wrapper{
    display: flex;
    width: 100%;
    height: 99%;
    justify-content: center;
}
.question-card{
    max-height: 100%;
    width: 100%;
    padding-top: 1em;
    padding-bottom: 1em;
    align-self: center;
}
.question-in-wrapper{
    display: flex;
    flex-direction: column;
    justify-content: space-between !important;
    height: 100%;
}
.question-wrapper .question-info {
    display: flex;
    flex-wrap: wrap;
}
.question-wrapper .question-info .question-order {
    top: 0 !important;
    color: #000000 !important;
    display: inline-block;
    flex: 0 0 1em;
    font-weight: 500;
    position: relative;
    font-size: 1.22rem;
    line-height: 34px;
    white-space: nowrap;
    padding-right: .2em;
}
.question-title-wrapper{
    flex: 1;
    position: relative;
}
.question-title{
    color: #000000 !important;
    border-color: #000000 !important;
    font-weight: 500;
    font-size: 1.22rem;
    margin-bottom: .5em;
    display: inline-block;
    line-height: 1.5 !important;
}
.q-title-p{
    display: block;
    word-break: break-word;
    min-height: 1em;
    line-height: 33px !important;
}
.question-description{
    font-style: italic;
    font-size: .9em;
    transition: max-height .3s linear;
    letter-spacing: .2px;
    opacity: .8;

}
.question-body .input-choice label {
    color: #131212 !important;
    outline-color: transparent !important;
}
.form-input-choice:hover {
    background-color: rgba(255, 255, 255, 0.57) !important;
    border-color: #000000 !important;
}
.input-choice label{
    display: flex;
    border-radius: 8px;
    border: 1px solid #dedede;
    margin-bottom: .6em;
    background-color: #fff;
    word-break: break-word;
    min-height: 49px;
    padding: 1em .88em;
    align-items: baseline;
    border-radius: 14px !important;
}
.i-checkbox .input-choice:not(:checked) {
    position: absolute;
    opacity: 0;
    pointer-events: none;
    width: 1.5em;
    height: 1.5em;
    top: 6px;
}
.question-body .i-checkbox span .before-checkbox {
    background-color: rgba(255, 255, 255, 0.57) !important;
    border-color: rgba(99, 99, 99, 0.45) !important;
    height: 1.5em;
    width: 1.5em;
    border: 1px solid #d5d5d5;
    top: 0;
    z-index: 0;
    border-radius: 4px;
}
.input-choice label .label-text {
    font-size: 1.2em;
    margin-left: 2.4em;
}