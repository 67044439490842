.input_text, .input_select {
    font-weight: 300;
    width: 100%;
    margin: 0;
    outline: 0;
    line-height: normal;
    font-size: 15px;
    text-decoration: none;
    text-transform: none;
    letter-spacing: 0px;
    transition: color 0.3s ease, background-color 0.3s ease, border-color 0.3s ease;
    padding: 15px;
    border: 0.124rem solid black;
    -webkit-appearance: none; /* Remove default styling on Safari */
    appearance: none; /* Remove default styling */
    box-sizing: border-box; /* Ensure consistent sizing */
    background-clip: padding-box; /* Ensures the background is not affected by the border */
}

.input_text {
    -moz-appearance: textfield; /* Firefox: Remove spinner */
    -webkit-appearance: none;
    background: transparent;
}

.input_select {
    -webkit-appearance: none; /* Remove default select styling */
    appearance: none; /* Standard remove styling */
    background-image: url('data:image/svg+xml;charset=US-ASCII,<svg xmlns="http://www.w3.org/2000/svg" width="292.362" height="292.362"><path fill="#000" d="M287.54 69.68l-14.275-14.273c-5.857-5.857-15.355-5.857-21.213 0L146.181 161.278 40.308 55.405c-5.857-5.857-15.355-5.857-21.213 0L4.82 69.678c-5.857 5.858-5.857 15.355 0 21.213l130.683 130.681c5.856 5.857 15.355 5.857 21.213 0L287.54 90.894c5.858-5.857 5.858-15.355 0-21.214z"/></svg>'); /* Custom arrow */
    background-position: right 10px center; /* Position of the arrow */
    background-repeat: no-repeat; /* Don't repeat the arrow image */
}


.input-section{
    margin-bottom: 20px;
}
.error-border{
    border: 1px solid red !important;
}
.timeslot-section{
    /* border: 0.124rem solid black; */
    padding: 20px;

}
.store-detail{
    font-size: 16px;
}
.timeslot-heading{
    font-weight: 600;
    font-size: 18px;
    line-height: 18px;
    margin-bottom: 16px;
    text-transform: uppercase;
    color: black;
}
.t-heading{
    font-weight: 600;
    font-size: 18px;
    line-height: 18px;
    margin-bottom: 16px;
    color: black;
}
.slots-container{
    display: grid;
    gap: 8px;
    grid-template-columns: repeat(4, 1fr);
}
.btn-slot {
    font-weight: 400;
    font-size: 14px;
    line-height: 24px;
    -webkit-box-align: center;
    align-items: center;
    background-color: inherit;
    border: 1px solid rgb(87, 87, 87);
    color: rgb(0, 0, 0);
    display: flex;
    height: 36px;
    -webkit-box-pack: center;
    justify-content: center;
    text-align: center;
}
.btn-slot:hover{
    border: 2px solid rgb(0, 0, 0);
}
.btn-slot.active{
    background-color: #e2b9b3;
    color: white;
    border-color: #e2b9b3;
}

@media (min-width: 78.75rem) {
    .slots-container {
        /* grid-template-columns: repeat(7, 1fr); */
    }
}  
.order-summary{
    margin-top: 10px;
    border-radius: 0 !important;
    background-color: #efeded;
} 
.sr-input {
    font-size: 16px;
  }
  
.sr-card-element {
    padding-top: 12px;
}