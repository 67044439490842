.w-form{
    width: 550px;
    margin: 40px auto;
    max-width: 100%;
    transition: opacity .5s ease, transform .5s ease;
}

body{
  transition: .5s all;
}

.mt-30{
    margin-top: 30px;
}

.padc{
    padding: 13px 15px;
}

.form-check-input{
    border-color: #e2b9b3;
}

.min-height{
    min-height: 100vh;
}

.form-check-input {
    width: 1.2rem !important;
    height: 1.2rem !important;
    border-width: 1.5px !important;
    margin-bottom: 7px;
    margin-right: 10px;
}

.form-check-input:focus{
    box-shadow: none;
}

.fade-in {
  opacity: 1;
  transform: translateX(0);
}

.fade-out {
  opacity: 0;
  transform: translateX(-20px);
}

.fade-inn {
  opacity: 1;
  transform: translateX(0);
}

.fade-outt {
  opacity: 0;
  transform: translateX(-100px);
}

.animatecs{
  transition: opacity .5s ease, transform .5s ease;
  height: 0;
}

.animatecs.active{
  height: auto;
}

.active-check{
  background-color: #e2b9b338;
}

.bullet{
  cursor: pointer;
  border: 1px solid #b8adad;
}

.label-cs{
  border: 1px solid #333;
  border-radius: 50%;
  height: 20px;
  line-height: 31px;
  width: 20px;
  transition: background-color .3s ease;
}

.label-check{
  border-radius: 4px;
  border: 1px solid #333;
  height: 20px;
  line-height: 31px;
  width: 20px;
  transition: background-color .3s ease;
}

.bullet.active-check .label-cs{
  background: url(../../../public/assets/images/check.svg);
  background-position: 50%;
  background-repeat: no-repeat;
  background-size: 12px;
  color: #0000;
  background-color: #333;
}

.bullet.active-check .label-check{
  background: url(../../../public/assets/images/check.svg);
  background-position: 50%;
  background-repeat: no-repeat;
  background-size: 12px;
  color: #0000;
  background-color: #333;
}

.bullet:hover .label-cs {
  background-color: #333 !important;
}

.bullet:hover .label-check {
  background-color: #333 !important;
}
.btn-disable{
    background-color: #c7c2c2;
    padding: 1rem 3.1rem;
    text-align: center;
    display: block;
    width: 100%;
    color: #fff;
    transition: .3s all;
    border: 0px;
    position: relative;
    background-position: left bottom;
    z-index: 1;
}
.quiz-text{
  background: transparent;
  border: 1px solid #b8adad;
}

.conslut-loading {
  transform: rotateZ(45deg);
  perspective: 1000px;
  border-radius: 50%;
  display: inline-block;
  width: 48px;
  height: 48px;
  color: #b72727;
}
  .conslut-loading:before,
  .conslut-loading:after {
    content: '';
    display: block;
    position: absolute;
    top: 0;
    left: 0;
    width: inherit;
    height: inherit;
    border-radius: 50%;
    transform: rotateX(70deg);
    animation: 1s spin linear infinite;
  }
  .conslut-loading:after {
    color: #FF3D00;
    transform: rotateY(70deg);
    animation-delay: .4s;
  }

@keyframes rotate {
  0% {
    transform: translate(-50%, -50%) rotateZ(0deg);
  }
  100% {
    transform: translate(-50%, -50%) rotateZ(360deg);
  }
}

@keyframes rotateccw {
  0% {
    transform: translate(-50%, -50%) rotate(0deg);
  }
  100% {
    transform: translate(-50%, -50%) rotate(-360deg);
  }
}

@keyframes spin {
  0%,
  100% {
    box-shadow: .2em 0px 0 0px currentcolor;
  }
  12% {
    box-shadow: .2em .2em 0 0 currentcolor;
  }
  25% {
    box-shadow: 0 .2em 0 0px currentcolor;
  }
  37% {
    box-shadow: -.2em .2em 0 0 currentcolor;
  }
  50% {
    box-shadow: -.2em 0 0 0 currentcolor;
  }
  62% {
    box-shadow: -.2em -.2em 0 0 currentcolor;
  }
  75% {
    box-shadow: 0px -.2em 0 0 currentcolor;
  }
  87% {
    box-shadow: .2em -.2em 0 0 currentcolor;
  }
}


    

