.footer-new{
    background-color: #F2F2F2;
    padding: 70px 0 10px 0;
}

.title{
    font-size: 15px;
    color: #141413 !important;
    margin-bottom: 20px;
}

.desc{
    font-size: 12px;
    font-weight: 300;
    line-height: 1.6em;
    letter-spacing: 0px;
    color: #696969;
}

.list>ul{
    list-style: none;
    padding-left: 0px;
    margin: 0;
    column-count: 2;
}

.list>ul>li>a{
    text-decoration: none;
    padding: 10px 0;
}

.list p:hover{
    color: #d17b4c !important;
}

.mar-list{
    margin-left: 40px;
}

.fs-13{
    font-size: 13px !important;
}
/* 2025 */
.footer-title.title{
    font-size: 16px;
    line-height: 16px;
    margin: 1rem 0;
}
.footer-new .metropill.desc-new a{
    color: rgba(105, 105, 105, 1);
    font-family: 'metropillthinner' !important;
    font-size: 15px;
    line-height: 24px;
}
.footer-new .metropill.desc-new {
    font-size: 15px;
    line-height: 24px;
    color: rgba(105, 105, 105, 1);
    font-family: 'metropillthinner' !important;
    margin-bottom: 5px !important;
}
.footer-new .metropill.link-new{
    font-size: 14px;
    line-height: 22px;
    color: rgba(105, 105, 105, 1);
    font-family: 'metropillthinner' !important;
}
.footer-new .metropill.link-new:hover{
    color: #E2B9B3;
}
.mar-list .list li {
    margin-bottom: 10px;
    padding-left: 50px;
}
.gap-10p{
    gap: 10px;
}
.col-xl-6.col-lg-4 h4.fw-bold.title.metropill.footer-title {
    text-align: center;
}
.list {
    margin: 40px 0px 0px 0;
}
.mar-list {
    margin: 0 auto;
    max-width: 472px;
}
.col-xl-6.col-lg-4 + .col-xl-3.col-lg-4 {
    text-align: end;
}
.footer-new .col-xl-6.col-lg-4 + .col-xl-3.col-lg-4 .metropill.desc-new{
    font-size: 14px;
    line-height: 24px;
}
.logo-footer{
    width: 290px;
}
.reg-area p{
    font-size: 14px;
    line-height: 14px;
    margin-bottom: 20px !important;
    color: #000;
    font-family: 'metropillthinner' !important;

}
.copy-right-para {
    padding: 20px 0;
    font-size: 16px;
    line-height: 25px;
    font-family: 'metropillthinner' !important;
}
.social-icons img{
    width: 23px;
    margin-left: 8px;
    margin-bottom: 5px;
}
.social-icons:nth-last-child(1) img {
    width: 33px;
    margin-top: 5px;
    margin-left: 5px;
}
@media (max-width: 991px){
    .mar-list{
        margin-left: 0px;

        margin: 20px 0;
    }
    .list,
    .mar-list {
        margin: 0;
    }
    .footer-title.title{
        margin: 1rem 0;
    }
    .footer-title.title {
        margin: 2.5rem 0 1rem;
    }
    .reg-area{
        margin-top: 2.5rem;
    }
}